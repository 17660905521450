import * as React from "react"
import {css} from "@emotion/react"
import GreenHR from "../hr";

const Heading = ({children}) => {
  return (
    <>
      <div css={css` display: grid; grid-template-columns: auto; width: fit-content; margin: 0 auto;`}>
        <h1 css={css` margin-bottom: 8px; `}>{children}</h1>
        <GreenHR />
      </div>
    </>
  )
}

export default Heading