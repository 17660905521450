import * as React from "react"
import { css, keyframes } from "@emotion/react"

const GreenHR = () => {
  const straightGreenLine = keyframes`
        0% {
            background-position: 2%;
        }
        50% {
            background-position: 100% 99%;
        }
        100% {
            background-position: 2%;
        }
`

  return (
    <div
      css={css`
        margin: 0 1rem 1rem 1rem;
        height: 8px;
        background: linear-gradient(to right, var(--green), var(--darkGreen));
        background-size: 400% 400%;
        animation: ${straightGreenLine} 6s ease infinite;
      `}
    />
  )
}

export default GreenHR
