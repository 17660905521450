import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LocalBusinessSchemaHook = () => {
  const { site } = useStaticQuery(
    graphql`
        {
            site {
                siteMetadata {
                    title
                    siteUrl
                    siteLogo
                    phoneNumber
                }
            }
        }
    `
  )

  const localBusinessSchema = `
  {
	"@context": "https://schema.org/AccountingService",
	"@type": "AccountingService",
	"name": "${site.siteMetadata.title}",
	"image": "${site.siteMetadata.siteUrl}${site.siteMetadata.siteLogo}",
	"@id": "https://labellebookkeeping.com/#website",
	"url": "https://labellebookkeeping.com/",
	"telephone": "${site.siteMetadata.phoneNumber}",
	"priceRange": "$-$$$$$",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "6032 Rexford Drive",
		"addressLocality": "Chilliwack",
		"addressRegion": "BC",
		"postalCode": "V2R 0L3",
		"addressCountry": "CA"
	},
	"geo": {
		"@type": "GeoCoordinates",
		"latitude": 49.11297,
		"longitude": -121.912225
	},
	"openingHoursSpecification": {
		"@type": "OpeningHoursSpecification",
		"dayOfWeek": [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday"
		],
		"opens": "09:00",
		"closes": "17:00"
	}
}
`
  // "sameAs": [
  //   "https://www.instagram.com/ecotech_restoration_inc/",
  //   "https://www.facebook.com/ecotechrestorationinc/",
  //   "https://twitter.com/Ecotech_123"
  // ]
  return  (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: localBusinessSchema}} />
  )
}

export default LocalBusinessSchemaHook