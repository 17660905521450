import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Heading from "../components/typography/heading"
import JotFormReact from "jotform-react"
import ContentWrapper from "../components/wrappers/contentWrapper";
import LocalBusinessSchemaHook from "../components/schema/localBusiness";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Labelle Bookkeeping Contact"
           description="Contact Labelle Bookkeeping today!"
           canonical="https://labellebookkeeping.com/contact/"
      />
      <LocalBusinessSchemaHook />
      <Heading>Contact Labelle Bookkeeping</Heading>

      <ContentWrapper>
        <JotFormReact
          formURL="https://form.jotform.com/220317917273253"
          autoResize={true}
        />
      </ContentWrapper>

    </Layout>
  )
}

export default ContactPage